/**
 * @param {string} flagName
 * @returns {boolean}
 */
const isActive = (flagName) => {
    const flags = getAll()
    if (typeof flags['FEATURE_ALL'] !== 'undefined') {
        return !!flags['FEATURE_ALL']
    }

    return flags[flagName] ?? false
}

/**
 * @returns {{}}
 */
const getAll = () => {
    return window.__FEATURE_FLAGS__ ?? {}
}

export { getAll, isActive }

export default (app) => {
    app.config.globalProperties.$feature = {
        getAll,
        isActive,
    }
}
